
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.App {
  margin: 0;
  padding: 0;
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.phone-number {
  background-image: linear-gradient(to bottom, rgb(255, 255, 255), rgb(236, 236, 1));
  text-align: center;
}
.App img {
  max-width: 100%;
  max-height: 90vh;
  margin: 0 auto;
}

.header-banner {
  width: 100%;
  background-image: linear-gradient(to bottom, rgb(25, 232, 255), rgb(162, 162, 162));
  color: white;
  font-size: x-large;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  margin: 0;
  height: 3rem;
}

.header-text {
  width: 70%;
  font-size: large;
  margin: 0 auto;
  padding: 2rem 10px;
  text-align: left;
}
.header-text p {
  font-weight: 500;
}

.faq {
  padding: 2rem 15px;
  background-image: linear-gradient(to bottom, rgb(25, 232, 255), rgb(162, 162, 162));
  color: rgb(255, 255, 255);
  text-align: center;
}

.faq-heading {
  color: rgb(255, 255, 255);
}

.location {
  padding: 2rem 20px;
}

a:link, a:visited, a:hover, a:active {
  color: Red;
  text-decoration: none;
}

@media screen and (max-width: 500px) {
  .header-banner {
    font-size: large;
  }
  .header-text {
    width: 100%;
  }
  .faq {
    text-align: left;
    width: 100%;
  }
}